import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../providers/AuthProvider'
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";
import { ArrowHeadRightIcon, Spacer } from '../../Constants';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { toast } from "react-toastify";


const SignIn = (props) => {
  const {  inputs, setInputs, errors, setErrors, user } = useContext(firebaseAuth)
  const [redirect, setRedirect] = useState(false)
  // const [firstLoad, setFirstLoad] = useState(false)
  let useQuery = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  useEffect(() => {

    if (user.isAuthenticated)
      setRedirect(true);
  }, [user]);
  useEffect(() => {
    let pw_update = useQuery.get('pw_update');
    if(pw_update)
      toast.success('Password set successfully.  Please login below.');
    setInputs({ email: '', password: '',pw_update: pw_update ? true : false });
    setErrors([]);
  }, []);

  if (redirect)
    navigate('/manage');
    
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('logging in');

    firebase.auth().signInWithEmailAndPassword(inputs.email, inputs.password)
      .then(data => {
        db.collection("invites")
        .where("email", "==", inputs.email)
        .get()
        .then(function (snapshot) {
          snapshot.docs.forEach(d => {
            db.collection("invites")
            .doc(d.id)
            .delete();
          });
        });
      })
      .catch(error => {
        toast.error("Username or password are incorrect.");
        console.error("Error signing in with password and email", error);
      });  }

  const handleChange = e => {
    const { id, value } = e.target
    setInputs(prev => ({ ...prev, [id]: value }))
  }

  return (
    <div className="container">
      <Spacer size="2" />
      <h1>Sign-In</h1>

      <Spacer size="1" />
      <Form onSubmit={handleSubmit}>
        <FormGroup controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control type="email" onChange={handleChange} />
        </FormGroup>
        <FormGroup controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" onChange={handleChange} />
        </FormGroup>
        <Button type="submit"><i className="light"><ArrowHeadRightIcon fill="#FFFFFF" /></i> <span>Login</span></Button>
        <div className="py-1"></div>
        <div className="row">
          <div className="col-auto">
            <Link to="/manage/forgot-password">Forgot Password?</Link>

          </div>
        </div>
        <Spacer size="2" />
        {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
      </Form>
    </div>
  );
};

export default SignIn;