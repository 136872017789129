import React, { useState, useEffect, useRef, useMemo, useCallback, useContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import "firebase/storage";
import { db } from "../../../firebase";
import "firebase/functions";
import {
  DeleteIcon,
  CheckIcon,
  DLAbbrDesMap,
  DownloadIcon,
} from "../../../Constants";
import "../../../assets/css/groups.css";
import { EventDataContext } from "../EventDataContext";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import "../../../assets/css/registrations.css";
import DOMPurify from "dompurify";
import * as html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import BarcodeReader from "react-barcode-reader";
import moment from "moment";
import { PCNANavbar } from "../../main/PCNANavbar";
import { saveAs } from "file-saver";
import CsvDownloadButton from "react-json-to-csv";
import JoditEditor from "jodit-react";

const Registrations = () => {
  const storage = firebase.storage().ref();
  const history = useNavigate();
  const [regFilters, setRegFilters] = useState({
    search: "",
    status: "",
    paymentStatus: "",
    waiver: "",
    pageIndex: 0,
  });
  const [allSelected, setAllSelected] = useState(false);
  const [importReg, setImportReg] = useState(false);
  const [createReminder, setCreateReminder] = useState(false);
  const [showFieldList, setShowFieldList] = useState(false);
  const [availableFieldList, setAvailableFieldList] = useState(["company", "title", "firstName", "lastName", "email", "phone", "address1", "address2", "city", "state", "postal", "createdDate", "updatedDate", "status","paymentStatus", "dealer", "experience", "modelInterest", "purchaseTimeframe"]);

  const [sending, setSending] = useState(0);

  const [commItem, setCommItem] = useState(false);
  const [emailHtml, setEmailHtml] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailTemplate, setEmailTemplate] = useState("");
  const editor = useRef(null);
  const [previewEmailHtml, setPreviewEmailHtml] = useState(null);
  const [reminderEmailList, setReminderEmailList] = useState([]);
  const [regCount, setRegCount] = useState(false);
  const [regItemCounts, setRegItemCounts] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [allSubmissions, setAllSubmissions] = useState([]);
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });
  const { eventData, setEventData } = useContext(EventDataContext);
  const [fieldList, setFieldList] = useState({ company: false, title: false, firstName: true, lastName: true, email: true, phone: false, address1: false, address2: false, city: false, state: false, postal: false, createdDate: true, updatedDate: false, status: true, paymentStatus: false, guest: false, waiver: false, dealer: false, experience: false, modelInterest: false, purchaseTimeframe: false })

  const [data, setData] = useReducer(reducer, {
    registrations: [],
    isFetching: true,
    message: null,
  });
  const downloadPdfDocuments = async () => {
    // console.log(emailHtml);
    if (data?.filtered?.length > 0) {
      if (!emailHtml) {
        alert('No email message found.');
        return;
      }
      if (!emailSubject) {
        alert('No email subject found.');
        return;
      }
      let total = reminderEmailList.length;
      let success = 0;
      let incomplete = 0;
      let failed = 0;
      // setSending(1);
      var zip = new JSZip();

      // setPreviewEmailHtml('Archiving ' + 0 + ' of ' + total + ', please wait.');
      for (var i = 0; i < reminderEmailList.length; i++) {
        let x = reminderEmailList[i];
        // setPreviewEmailHtml('Sending ' + i + ' of ' + total + ', please wait.');
        if (emailHtml && emailSubject) {
          var sendEmail = firebase
            .functions()
            .httpsCallable("app/sendConfirmationEmail");
          await sendEmail({ reg: data?.filtered[x], eventData: eventData, preview: true, nofooter: true, html: emailHtml, subject: emailSubject })
            .then((result) => {
              if (result?.data.error) {
                toast.warning(result?.data.message);
                failed++;
              }
              else {
                var html_string = result?.data.message;
                var iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                setTimeout(function () {
                  var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
                  iframedoc.body.innerHTML = html_string;
                  html2canvas(iframedoc.body, {
                    useCORS: true,
                    allowTaint: false,
                    scale: 1,
                    width: 800,
                    windowWidth: 800
                  }).then(function (canvas) {
                    success++;
                    iframe.remove();
                    var width = canvas.width;
                    var height = canvas.height;
                    // console.log(width);
                    // console.log(height);
                    var millimeters = {};
                    millimeters.width = Math.floor(width * 0.264583);
                    millimeters.height = Math.floor(height * 0.264583);
                    if (millimeters.height > 297) {
                      let ratio = 297 / millimeters.height;
                      millimeters.height = 297;
                      millimeters.width = millimeters.width * ratio;
                    }

                    var imgData = canvas.toDataURL(
                      'image/jpeg', 1.0);
                    var pdf = new jsPDF("p", "mm", "a4");
                    // pdf.setFont('Helvetica');
                    pdf.addImage(imgData, 'JPEG', 0, 0, millimeters.width, millimeters.height);  // 180x150 mm @ (10,10)mm
                    // pdf.save('sample-file.pdf');
                    zip.file(data?.filtered[x].id + '.pdf', pdf.output('blob'));

                    if (incomplete + failed + success == total) {
                      zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, 'reports.zip');
                      });
                    }
                  });

                }, 10);

              }
            })
            .catch((e) => {
              // Sentry.captureException(e);
              toast.error(e.message);
            });
        } else {
          incomplete++;
        }
      }
      // setSending(2);
      // setPreviewEmailHtml("Send complete.<br/>Success: " + success + "<br/>Incomplete: " + incomplete + "<br/>Failed: " + failed + "<br/>Total: " + total + "<br/>");
      // setEmailHtml("");
      // setEmailSubject("");
      setReminderEmailList([]);
      var ele = document.getElementsByName('reminderCheck');
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == 'checkbox')
          ele[i].checked = false;
      }

    }
  }

  const filter = () => {
    let registrations = data.registrations;
    if (!registrations || registrations.length == 0) {
      // let tempReg = JSON.parse(localStorage.getItem('regData'));
      // if(!tempReg || tempReg.length == 0) {
      //   tempReg = [];
      // }
      // registrations = tempReg;
      // console.log("in filter");
      fetch();
      return;
    }
    let filteregReg = [];
    registrations.map((reg, i) => {
      reg.idx = i;
      if (regFilters && !regFilters.status && !regFilters.paymentStatus && !regFilters.search && !regFilters.waiver) {
        filteregReg.push(reg);
      } else {
        let searchCheck = !regFilters?.search;
        let waiverCheck = !regFilters?.waiver;
        let statusCheck = !regFilters?.status;
        let paymentStatusCheck = !regFilters?.paymentStatus;
        if (!searchCheck) {
          if (
            reg.company
              ?.toUpperCase()
              .indexOf(regFilters?.search?.toUpperCase()) > -1 ||
            reg.firstName
              ?.toUpperCase()
              .indexOf(regFilters?.search?.toUpperCase()) > -1 ||
            reg.lastName
              ?.toUpperCase()
              .indexOf(regFilters?.search?.toUpperCase()) > -1 ||
            reg.email?.toUpperCase().indexOf(regFilters?.search?.toUpperCase()) >
            -1
          ) {
            searchCheck = true;
          }
        }
        if (!statusCheck) {
          if (reg.status == regFilters?.status) {
            statusCheck = true;
          }
        }
        // console.log(regFilters.paymentStatus);
        // console.log(reg.adyenPaymentStatus);
        // console.log('1');
        if (!paymentStatusCheck) {
          if (reg.adyenPaymentStatus == regFilters?.paymentStatus) {
            paymentStatusCheck = true;
          }
        }
        if (!waiverCheck) {
          if (
            (regFilters.waiver == "Yes" && reg.hasWaiver) ||
            (regFilters.waiver == "No" && !reg.hasWaiver)
          ) {
            waiverCheck = true;
          }
        }
        // console.log(searchCheck);
        // console.log(statusCheck);
        // console.log(paymentStatusCheck);
        // console.log(waiverCheck);
        if (searchCheck && statusCheck && paymentStatusCheck && waiverCheck) filteregReg.push(reg);
      }
    });
    setData({ ...data, filtered: filteregReg, filter: false });
  };
  const previewEmail = async () => {
    // console.log(emailHtml);
    if (data?.filtered?.length > 0) {
      var sendEmail = firebase
        .functions()
        .httpsCallable("app/sendConfirmationEmail");
      sendEmail({ reg: data?.filtered[0], eventData: eventData, preview: true, html: emailHtml, subject: 'Test Subject' })
        .then((result) => {
          if (result?.data.error) {
            toast.warning(result?.data.message);
            return;
          }
          else {
            setPreviewEmailHtml(result?.data.message);

          }
        })
        .catch((e) => {
          // Sentry.captureException(e);
          toast.error(e.message);
        });

    }
  }

  const sendEmail = async () => {
    // console.log(emailHtml);
    if (data?.filtered?.length > 0) {
      if (!emailHtml) {
        alert('No email message found.');
        return;
      }
      if (!emailSubject) {
        alert('No email subject found.');
        return;
      }
      let total = reminderEmailList.length;
      let success = 0;
      let incomplete = 0;
      let failed = 0;
      setSending(1);
      setPreviewEmailHtml('Sending ' + 0 + ' of ' + total + ', please wait.');
      for (var i = 0; i < reminderEmailList.length; i++) {
        let x = reminderEmailList[i];
        setPreviewEmailHtml('Sending ' + i + ' of ' + total + ', please wait.');
        if (data?.filtered[x].email && emailHtml && emailSubject) {
          var sendEmail = firebase
            .functions()
            .httpsCallable("app/sendConfirmationEmail");
          await sendEmail({ reg: data?.filtered[x], eventData: eventData, preview: false, html: emailHtml, subject: emailSubject })
            .then((result) => {
              if (result?.data.error) {
                toast.warning(result?.data.message);
                failed++;
              }
              else {
                success++;
              }
            })
            .catch((e) => {
              // Sentry.captureException(e);
              toast.error(e.message);
            });
        } else {
          incomplete++;
        }
      }
      setSending(2);
      setPreviewEmailHtml("Send complete.<br/>Success: " + success + "<br/>Incomplete: " + incomplete + "<br/>Failed: " + failed + "<br/>Total: " + total + "<br/>");
      // setEmailHtml("");
      // setEmailSubject("");
      setReminderEmailList([]);
      var ele = document.getElementsByName('reminderCheck');
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == 'checkbox')
          ele[i].checked = false;
      }
    }
  }
  const fetch = () => {
    // console.log("fetching data");
    let tmpItems = [];
    if (!eventData.id) return;
    let tmpSortOrder = sortOrder
      ? sortOrder
      : JSON.parse(localStorage.getItem("sortOrder"));
    let so = tmpSortOrder?.split("|");
    let cutoff = moment().add(-900, 'days');
    if (eventData.endDate) { // && moment(eventData.endDate).isAfter(cutoff)) {
      if (so && so.length == 2) {
        db.collection("registrations")
          .where("eventId", "==", eventData.id)
          .orderBy(so[0], so[1])
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              let tmpItem = doc.data();
              tmpItem.id = doc.id;
              if (tmpItem.createdAt && !tmpItem.createdDate)
                try {
                  if (tmpItem.createdAt.seconds) {

                    // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                    tmpItem.createdDate = moment((tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000)).format(
                      "MM/DD/YYYY h:mm A"
                    );
                  } else {
                    // alert('ehllo2');
                    tmpItem.createdDate = moment(tmpItem.createdAt).format(
                      "MM/DD/YYYY h:mm A"
                    );
                  }
                } catch {

                }
              if (tmpItem.updatedAt && !tmpItem.updatedDate)
                try {
                  if (tmpItem.updatedAt.seconds) {

                    // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                    tmpItem.updatedDate = moment((tmpItem.updatedAt.seconds * 1000 + tmpItem.updatedAt.nanoseconds / 1000000)).format(
                      "MM/DD/YYYY h:mm A"
                    );
                  } else {

                    tmpItem.updatedDate = moment(tmpItem.updatedAt).format(
                      "MM/DD/YYYY h:mm A"
                    );
                  }
                } catch {

                }
              tmpItems.push(tmpItem);
            });
            setData({
              registrations: tmpItems,
              isFetching: false,
              message: null,
              filtered: null,
              filter: true,
            });
            // localStorage.setItem('regData',JSON.stringify(tmpItems));
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
            setData({
              tmpItems: data.registrations,
              isFetching: false,
              filtered: null,
              filter: true,
              message: null,
            });
          });
      } else {
        db.collection("registrations")
          .where("eventId", "==", eventData.id)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              let tmpItem = doc.data();
              tmpItem.id = doc.id;
              // console.log(tmpItem);
              if (tmpItem.createdAt && !tmpItem.createdDate) {
                if (tmpItem.createdAt.seconds) {

                  // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                  tmpItem.createdDate = moment((tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000)).format(
                    "MM/DD/YYYY h:mm A"
                  );
                } else {
                  // alert('ehllo2');
                  tmpItem.createdDate = moment(tmpItem.createdAt).format(
                    "MM/DD/YYYY h:mm A"
                  );
                }

              }
              tmpItems.push(tmpItem);
            });
            setData({
              registrations: tmpItems,
              isFetching: false,
              message: null,
              filtered: null,
              filter: true,
            });
            localStorage.setItem("regData", JSON.stringify(tmpItems));
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
            setData({
              tmpItems: data.registrations,
              isFetching: false,
              filtered: null,
              filter: true,
              message: null,
            });
          });
      }
    } else if (!eventData.endDate) {
      setData({
        registrations: null,
        isFetching: false,
        message: "Event must have an end date to show registrations to comply with PCNA data security requirements.",
        filtered: null,
        filter: false,
      });
    } else {
      setData({
        registrations: null,
        isFetching: false,
        message: "Data has been removed 90 days after the event to comply with PCNA data security requirements.",
        filtered: null,
        filter: false,
      });
    }
  };
  const getRegCount = async () => {
    let regInfo = await firebase.functions().httpsCallable("app/regCount");
    let result = await regInfo({ id: eventData?.id });
    setRegCount(result.data);
  }
  const getItemCapacity = async (eventId, item,index) => {
    let itemInfo = await firebase.functions().httpsCallable("app/regItemCount");
    let result = await itemInfo({ id: eventId, fieldName: item.fieldName });
    setRegItemCounts((regItemCounts) => [...regItemCounts, {sortOrder:index, 'item': item.fieldLabel, 'regItemCount': result.data, 'fieldCapacityNum': item.fieldCapacityNum }]);

    

  }
  const handleChangeItem = useCallback((idkey, value) => {

    setEmailHtml(value);
  }, []);
  const updateItemCapacity = async () => {
    let tmpRegItemCounts = [];
    let i = 0;
    // await eventData.additionalFields.map(async (item, index) => {
      if(eventData.additionalFields){
     eventData.additionalFields.forEach((item) => {
        if (item.fieldType === "CommerceItem") {
          if (item.fieldCapacity === "itemCapacity") {
            getItemCapacity(eventData.id, item,i);
            i++;
            // console.log(tmpItem);
            // console.log(tmpRegItemCounts);

            
          }
        }
        
      });
    }
    
    
    // tmpRegItemCounts.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);
    // setRegItemCounts(tmpRegItemCounts);
  }
  useEffect(() => {
    let tmpFieldList = fieldList;
    let tmpAvailableFieldList = availableFieldList;

    if (eventData.allowGuests)
      tmpAvailableFieldList.push("guest");
    if (eventData.allowWaivers)
      tmpAvailableFieldList.push("waiver");

    if (eventData.id) {
      // let tempReg = JSON.parse(localStorage.getItem('regData'));
      let tempReg = null;
      if (!tempReg || tempReg.length == 0) {
        tempReg = [];
      }
      setData({
        isFetching: tempReg.length == 0,
        message: null,
        filter: tempReg.length > 0,
      });
      updateItemCapacity();
      if (eventData.additionalFields) {
        let isComm = false;
        eventData.additionalFields.map((item, index) => {
            tmpFieldList[item.fieldName] = false;
          tmpAvailableFieldList.push(item.fieldName);
          if (item.fieldType === "CommerceItem") {

            tmpFieldList[item.fieldName + "Cost"] = false;
            tmpAvailableFieldList.push(item.fieldName + "Cost");
            if(!isComm) {
            isComm = true;
            setCommItem(isComm);
            }
          }
        });
        if (isComm) {
          tmpFieldList["subCost"] = false;
          tmpFieldList["paymentStatus"] = false;
          tmpFieldList["taxCost"] = false;
          tmpFieldList["totalCost"] = false;
          tmpFieldList["adyenRef"] = false;
          tmpFieldList["adyenLink"] = false;
          if (eventData.paymentTax) {
            tmpAvailableFieldList.push("subCost");

            tmpAvailableFieldList.push("taxCost");
          }
          tmpAvailableFieldList.push("totalCost");
          tmpAvailableFieldList.push("adyenRef");
          tmpAvailableFieldList.push("adyenLink");
        }
        let storedFieldList = JSON.parse(localStorage.getItem('fieldList'));
        let newFieldList = { ...tmpFieldList, ...storedFieldList };

        setFieldList(newFieldList);
        // console.log(tmpAvailableFieldList);
        setAvailableFieldList(tmpAvailableFieldList);
      }
      getRegCount();
      setRegFilters(JSON.parse(localStorage.getItem("regFilters")));
      setSortOrder(JSON.parse(localStorage.getItem("sortOrder")));
      fetch();
    }
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });


  }, []);
  
  useEffect(() => {
    // console.log("in data");
    if (!data.isFetching && data.filter) {
      filter();
    }
  }, [data]);
  useEffect(() => {
    // console.log("inregfilters");
    setAllSelected(false);
    setReminderEmailList([]);
    var ele = document.getElementsByName('reminderCheck');
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type == 'checkbox')
        ele[i].checked = false;
    }
    localStorage.setItem("regFilters", JSON.stringify(regFilters));
    if (!data.isFetching) filter();
  }, [regFilters]);
  useEffect(() => {
    // console.log("in sort order");
    localStorage.setItem("sortOrder", JSON.stringify(sortOrder));
    if (data.isFetching) return;
    if (!eventData.id) return;
    fetch();
  }, [sortOrder]);
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading registrations.</div>;
    } else {
      return null;
    }
  }
  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }
  const deleteFile = (pathToFile, fileName) => {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    if (childRef) childRef.delete();
  };
  const downloadAllWaivers = async () => {
    if (user.role === 1 || user.role === 2) {
      var zip = new JSZip();
      var zipFilename = "waivers_" + eventData.id + ".zip";
      let total = data.registrations.length;
      let countReg = 0;

      data.registrations.map(async (reg) => {
        let filename = "filename";
        // console.log(countReg);
        // console.log(total);
        // console.log(zip);
        try {
          let url = await storage
            .child("/waivers/" + reg?.id + ".pdf")
            .getDownloadURL();
          // console.log(url);

          // loading a file and add it in a zip file
          // console.log(JSZipUtils);
          let file = await JSZipUtils.getBinaryContent(url);
          countReg = countReg + 1;
          // console.log(countReg);
          zip.file(reg?.id + ".pdf", file, { binary: true });
          if (countReg == total) {
            // console.log("generating zip");
            let content = await zip.generateAsync({ type: "blob" });

            saveAs(content, zipFilename);
          }
        } catch (e) {
          total = total - 1;
        }
      });
    } else {
      alert("Only an admin/manager can download all waivers.");
    }
  };
  const downloadSubmissions = async () => {
    let tmpAllSubmissions = [];
    // console.log(eventData.dataFields);

    data.registrations.map(async (reg, i) => {
      let tmpSubmission = {};
      tmpSubmission["id"] = reg["id"];

      // for (const key in eventData?.dataFields) {
      //   // console.log(eventData?.dataFields[key]);
      //   if(eventData?.dataFields[key].visible) {
      //     // console.log('saving ' + key);
      //     tmpSubmission[key] = reg[key];
      //   }
      // }
      if (eventData?.dataFields["company"]?.visible)
        tmpSubmission["company"] = reg["company"];
      if (eventData?.dataFields["title"]?.visible)
        tmpSubmission["title"] = reg["title"];
      if (eventData?.dataFields["lastName"]?.visible)
        tmpSubmission["lastName"] = reg["lastName"];
      if (eventData?.dataFields["firstName"]?.visible)
        tmpSubmission["firstName"] = reg["firstName"];
      if (eventData?.dataFields["email"]?.visible)
        tmpSubmission["email"] = reg["email"];
      if (eventData?.dataFields["phone"]?.visible)
        tmpSubmission["phone"] = reg["phone"];
      if (eventData?.dataFields["address1"]?.visible)
        tmpSubmission["address1"] = reg["address1"];
      if (eventData?.dataFields["address2"]?.visible)
        tmpSubmission["address2"] = reg["address2"];
      if (eventData?.dataFields["city"]?.visible)
        tmpSubmission["city"] = reg["city"];
      if (eventData?.dataFields["state"]?.visible)
        tmpSubmission["state"] = reg["state"];
      if (eventData?.dataFields["postal"]?.visible)
        tmpSubmission["postal"] = reg["postal"];
      if (eventData?.dataFields["dealer"]?.visible)
        tmpSubmission["dealer"] = reg["dealer"];
      if (eventData?.dataFields["experience"]?.visible)
        tmpSubmission["experience"] = reg["experience"];
      if (eventData?.dataFields["modelInterest"]?.visible)
        tmpSubmission["modelInterest"] = reg["modelInterest"];
      if (eventData?.dataFields["purchaseTimeFrame"]?.visible)
        tmpSubmission["purchaseTimeFrame"] = reg["purchaseTimeFrame"];
      let showPayment = false;
      for (const key2 in eventData?.additionalFields) {
        // console.log(eventData?.additionalFields[key2].fieldName);
        if (eventData?.additionalFields[key2].fieldType === "CommerceItem")
          showPayment = true;
        let tmpVal = reg[eventData?.additionalFields[key2].fieldName];
        if (typeof tmpVal === 'string' || tmpVal instanceof String) {
          tmpVal = tmpVal.replaceAll('"', '');
          tmpVal = tmpVal.replaceAll(',', '');

        }
        if (eventData?.additionalFields[key2].fieldName) {
          tmpSubmission[eventData?.additionalFields[key2].fieldName] = tmpVal;
          if (eventData?.additionalFields[key2].fieldType === "CommerceItem")
            tmpSubmission[eventData?.additionalFields[key2].fieldName + "Cost"] = reg[eventData?.additionalFields[key2].fieldName + "Cost"];

        }


      }
      // let tmpSubmission = {
      //   id: reg.id,
      //   firstName: reg.firstName,
      //   lastName: reg.lastName,
      //   email: reg.email,
      //   phone: reg.phone,
      //   status: reg.status,
      // };
      if (eventData.allowUpload) {
        var storageRef = firebase.storage().ref("/submissions/" + reg.id + "/");
        let tmpFile = [];
        let k = 1;
        let allFiles = await storageRef.listAll();
        allFiles.items.forEach(async (fileRef) => {
          let fileUrl = await fileRef.getDownloadURL();
          tmpSubmission["file" + k] = fileUrl;
          k++;
        });
      }
      //Guest?
      if (eventData.allowWaivers) {
        tmpSubmission["hasWaiver"] = reg["hasWaiver"];
      }
      if (showPayment) {
        if (eventData.paymentTax) {
          tmpSubmission["subCost"] = reg["subCost"];

          tmpSubmission["taxCost"] = reg["taxCost"];
        }
        tmpSubmission["totalCost"] = reg["totalCost"];
        tmpSubmission["adyenPaymentStatus"] = reg["adyenPaymentStatus"];
        tmpSubmission["adyenRef"] = reg["adyenRef"];
        tmpSubmission["adyenRefDate"] = reg["adyenRefDate"];
        tmpSubmission["adyenResultCode"] = reg["adyenResultCode"];
        tmpSubmission["adyenRefusalReason"] = reg["adyenRefusalReason"];
        tmpSubmission["adyenLink"] = reg["adyenLink"];
        tmpSubmission["adyenLinkId"] = reg["adyenLinkId"];
        tmpSubmission["adyenLinkExpire"] = reg["adyenLinkExpire"];
      }
      tmpSubmission["status"] = reg["status"];

      try {
        tmpSubmission["createdAt"] = reg["createdAt"] ? moment(reg["createdAt"].toDate()).format("MM/DD/YYYY h:mm:ss A") : "";
      } catch {
        tmpSubmission["createdAt"] = "N/A";
      }
      try {
        tmpSubmission["updatedAt"] = reg["updatedAt"] ? moment(reg["updatedAt"].toDate()).format("MM/DD/YYYY h:mm:ss A") : "";
      } catch {
        tmpSubmission["updatedAt"] = "N/A";
      }
      tmpAllSubmissions.push(tmpSubmission);

      setAllSubmissions(tmpAllSubmissions);
      // console.log(tmpAllSubmissions);
    });

  };
  const deleteAllRegistrations = () => {
    if (user.role === 1) {
      alert(
        "Registgrations are being deleted.  This may take several minutes."
      );
      data.registrations.map((reg, i) => {
        deleteFile("/waivers/", reg.id + ".pdf");
        deleteFile("/waivers/", reg.id + ".jpg");
        db.collection("registrations")
          .doc(reg.id)
          .delete();
      });
      // fetch()
      history("/manage/");
    } else {
      alert("Only an admin can delete all registrations.");
    }
  };
  const handleFileRead = async (event) => {
    let valid = 0;
    let invalid = 0;
    const file = event.target.files[0];
    if (file.name.indexOf(".csv") === -1) {
      alert(
        "File must be a valid csv file, download the provided template if needed."
      );
      return;
    }
    const csv = await processsFile(file);
    var allTextLines = csv.split(/\r\n|\n/);
    console.log(allTextLines.length);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(",");
      
      var tarr = [];
      console.log('here');
      // alert('fuck');
      if (data.length == 12 && i > 0) {
        valid = valid + 1;
        for (var j = 0; j < data.length; j++) {
          tarr.push(data[j]);
        }

        lines.push(tarr);
      } else if (data.length == 1 && data[0] == "") {
        // console.log("empty line");
        
      } else if (i > 0) {
        console.log(data);
        invalid = invalid + 1;
      }
    }
    console.log(invalid);
    lines.map((l, i) => {
      if (i == 0) return;
      let r = {};
      r.id = db.collection("registrations").doc().id;
      r.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      r.status = "Registered";
      r.eventId = eventData.id;
      r.company = l[0];
      r.title = l[1];
      r.firstName = l[2];
      r.lastName = l[3];
      r.email = l[4];
      r.phone = l[5];
      r.address1 = l[6];
      r.address2 = l[7];
      r.city = l[8];
      r.state = l[9];
      r.postal = l[10];
      r.dealer = l[11];
      // r.packages = l[12];
      db.collection("registrations")
        .doc(r.id)
        .set(r);
    });
    // console.log("fileread");
    toast.success(
      "Import complete: " + valid ? valid : 0 + " of " + (valid + invalid) + " records."
    );
    fetch();
    setImportReg(false);
  };
  const processsFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
      fileReader.readAsText(file);
    });
  };
  useEffect(() => {
    // console.log(4);
    checkAuth();
  }, [user]);
  useEffect(() => {
    // console.log(5);
    checkEventData();
  }, [eventData]);
  const checkEventData = () => {
    if (!eventData || !eventData.id) {
      toast.error(
        "Select to edit or view registrations for an event before continuing.."
      );
      history("/manage/events");
      return false;
    } else {
      return true;
    }
  };
  const checkAuth = () => {
    if (!user.isAuthenticated) {
      history("/manage/sign-in");
      return false;
    } else {
      return true;
    }
  };

  const alertCheck = (x, r) => {
    let alert = false;
    switch (x) {
      case "IncompleteWaiver":
        if (r.status !== "Waitlist" && r.status !== "Cancelled" && !r.hasWaiver)
          alert = true;
        break;
      case "IncompletePayment":
        if (r.status !== "Waitlist" && r.status !== "Cancelled" && (r.adyenPaymentStatus === "Pending" || r.adyenPaymentStatus === "" || !r.adyenPaymentStatus))
          alert = true;
        break;
      case "IsGuest":
        if (r.isGuest)
        alert = true;
        break;
      case "Pending":
        if (r.status === "Pending")
        alert = true;
        break;
      case "NotCheckedIn":
        if (r.status === "Registered" || r.status === "Pending")
        alert = true;
        break;
      case "CheckedIn":
        if (r.status === "Checked-In")
        alert = true;
        break;
      case "Waitlist":
        if (r.status === "Waitlist")
        alert = true;
        break;
      default:
        alert = false;
    }
    return alert;
  }
  const alertRules = (r) => {

    if (alertCheck(eventData?.redAlert, r)) {
      console.log('red');
      return "red-alert ";
    } else if (alertCheck(eventData?.yellowAlert, r)) {
      console.log('yellow');
      return "yellow-alert ";
    } else if (alertCheck(eventData?.orangeAlert, r)) {
      return "orange-alert ";
    } else if (alertCheck(eventData?.purpleAlert, r)) {
      return "purple-alert ";
    } else if (alertCheck(eventData?.blueAlert, r)) {
      return "blue-alert ";
    } else if (alertCheck(eventData?.greenAlert, r)) {
      return "green-alert ";

    }


    return "";
  }
  const clearReminder = () => {
    setCreateReminder(false);
    setSending(0);
    setPreviewEmailHtml("");
    setEmailHtml("");
    setEmailSubject("");
    setReminderEmailList([]);
    var ele = document.getElementsByName('reminderCheck');
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type == 'checkbox')
        ele[i].checked = false;
    }
  }
  const handleScan = (d) => {
    let d_arr = d.split("/");
    let regId = d_arr[d_arr.length - 1];
    history("/manage/registration/" + regId);
  };

  const handleScanError = (e) => {
    // setProgress("...error reading badge...");
    console.log(e);
  };
  return (
    <div className="container">
      <BarcodeReader onError={handleScanError} onScan={handleScan} />

      <h1>{eventData.sysname ? eventData.sysname : eventData.name}</h1>
      <h3>
        Registrations:{" "}
        <span className="fs-md">
          {data.filtered?.length ? data.filtered?.length : 0} of {data.registrations?.length}
        </span>
      </h3>
      <div className="row">
        {((regCount > 0 && +regCount !== data.registrations?.length) || eventData?.allowCapacity) &&
          <div className={regCount >= eventData?.capacity ? "col-12 col-md-4 red-alert":"col-12 col-md-4"}><label>Capacity:</label> {regCount} {eventData?.allowCapacity ? "of " + eventData?.capacity : ""}</div>
        }
        
        {[].concat(regItemCounts).sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map((r, i) => (

          <div className={r.regItemCount >= r.fieldCapacityNum ? "col-12 col-md-4 red-alert":"col-12 col-md-4"} key={i}><label>{r.item}:</label> {r.regItemCount} of {r.fieldCapacityNum}</div>


        ))}</div>
      <div className="py-2"></div>
      <div className="row">
        <div className="col-md">
          {!createReminder && (
            <div>
              <div className="py-1"></div>
              <button
                onClick={() => {
                  setCreateReminder(true);
                }}
                className="btn btn-theme"
              >
                Create Ad Hoc Email
              </button>
            </div>
          )}
          {createReminder && (
            <div>      <div className="pb-3">

              {previewEmailHtml && sending !== 1 &&
                <button className="btn btn-link" onClick={() => { setPreviewEmailHtml(null); }}>
                  Back
                </button>}

              {!previewEmailHtml && <>

                <div className="form-group">
                  <label>Email Templates</label>
                  <select
                    className="form-control"
                    name="emailTemplate"
                    value={emailTemplate}
                    onChange={(e) => {
                      let emailTemplate = e.target.value;
                      setEmailTemplate(emailTemplate);
                      if (emailTemplate === "Pending") {
                        setEmailSubject(eventData.pendingEmailSubject);
                        setEmailHtml(eventData.pendingEmailCopy);

                      } else if (emailTemplate === "Confirmation") {
                        setEmailSubject(eventData.confirmationEmailSubject);
                        setEmailHtml(eventData.confirmationEmailCopy);
                      } else if (emailTemplate === "Waitlist") {
                        setEmailSubject(eventData.waitlistEmailSubject);
                        setEmailHtml(eventData.waitlistEmailCopy);
                      } else if (emailTemplate === "Reminder") {
                        setEmailSubject(eventData.reminderEmailSubject);
                        setEmailHtml(eventData.reminderEmailCopy);
                      }
                    }}
                  >
                    <option value=""></option>
                    <option value="Pending">Pending Email</option>
                    <option value="Confirmation">Confirmation Email</option>
                    <option value="Waitlist">Waitlist Email</option>
                    <option value="Reminder">Reminder Email</option>
                  </select>
                </div>

                <div className="form-group">
                  <label
                    className="form-label form-help">
                    Ad Hoc Email Subject
                  </label>

                  <input
                    type="text"
                    id="emailSubject"
                    name="emailSubject"
                    value={emailSubject}
                    onChange={(e) => { setEmailSubject(e.target.value) }}
                    className="form-control"
                  />
                </div>
                <JoditEditor
                  ref={editor}
                  value={emailHtml}
                  // config={configJotit}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => setEmailHtml(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => setEmailHtml(newContent)}
                />

                <button className="btn btn-theme" onClick={() =>
                  window.confirm(
                    "Are you sure you want to send this email to " + reminderEmailList.length + " recipients?"
                  )
                    ? sendEmail()
                    : console.log("action cancelled by user.")
                }>
                  Send Email
                </button>&nbsp;&nbsp;
                <button className="btn btn-theme" onClick={() =>
                  window.confirm(
                    "Are you sure you want to download pdfs for " + reminderEmailList.length + " recipients?"
                  )
                    ? downloadPdfDocuments()
                    : console.log("action cancelled by user.")
                }>
                  Download Archive
                </button>&nbsp;&nbsp;
                <button className="btn btn-link" onClick={previewEmail}>
                  Preview Email
                </button>&nbsp;&nbsp;
                <button className="btn btn-default" onClick={() =>
                  window.confirm(
                    "Are you sure you want to cancel this email?"
                  )
                    ? clearReminder()
                    : console.log("action cancelled by user.")
                }>
                  Cancel
                </button>
              </>
              }
              <div className={previewEmailHtml ? "py-5" : "d-none"}>
                {/* <div className="pb-2"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      previewEmailHtml
                    ),
                  }}
                ></div> */}
                <h4 >Email {sending === 2 ? "Results" : "Preview"}</h4>
                <iframe className="previewEmail" srcDoc={previewEmailHtml} sandbox="allow-same-origin" id="htmlPreviewFrame" name="htmlPreviewFrame" />
              </div>
            </div></div>
          )}
          {!importReg && !createReminder && (
            <div>
              <div className="py-1"></div>
              <button
                onClick={() => {
                  setImportReg(true);
                }}
                className="btn btn-theme"
              >
                Import List
              </button>
            </div>
          )}
          {importReg && (
            <>
              <div className="form-group">
                <label className="form-label">Import List</label>
                <div>
                  <input
                    id="originalFileName"
                    type="file"
                    name="originalFileName"
                    onChange={(e) => handleFileRead(e)}
                  />
                </div>
              </div>
              <div>
                <a href="/assets/import-template.csv">
                  Download Import Template
                </a>
              </div>
              <div>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setImportReg(false);
                  }}
                >
                  Cancel
                </a>
              </div>
            </>
          )}
        </div>
        <div className="col-md text-end">
          <button
            onClick={() => history("/manage/registration")}
            className="btn btn-theme"
          >
            Add Registration
          </button>
        </div>
      </div>
      <div className="py-2"></div>
      <div className="row align-items-center">
        <div className="col-12">
          <div className="form-group">
            <label>&nbsp;</label>
            <input
              type="text"
              className="form-control"
              name="Search"
              value={regFilters?.search}
              onChange={(e) => {
                setRegFilters({ ...regFilters, search: e.target.value });
              }}
              placeholder="Search company, name, or email."
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>Status</label>
            <select
              className="form-control"
              name="StatusSearch"
              value={regFilters?.status}
              onChange={(e) => {
                setRegFilters({ ...regFilters, status: e.target.value });
              }}
            >
              <option value=""></option>
              <option value="Pending">Pending</option>

              <option value="Registered">Registered</option>
              <option value="Checked-In">Checked-In</option>
              <option value="Waitlist">Waitlist</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
        </div>
        {commItem &&
          <div className="col-2">
            <div className="form-group">
              <label>Payment Status</label>
              <select
                className="form-control"
                name="PaymentStatusSearch"
                value={regFilters?.paymentStatus}
                onChange={(e) => {
                  setRegFilters({ ...regFilters, paymentStatus: e.target.value });
                }}
              >
                <option value=""></option>
                <option value="Pending">Pending</option>
                <option value="Paid">Paid</option>
                <option value="Waived">Waived</option>
              </select>
            </div>
          </div>
        }
        <div className="col-2">
          <div className="form-group">
            <label>Waiver</label>
            <select
              className="form-control"
              name="WaiverSearch"
              value={regFilters?.waiver}
              onChange={(e) => {
                setRegFilters({ ...regFilters, waiver: e.target.value });
              }}
            >
              <option value=""></option>
              <option value="Yes">Complete</option>
              <option value="No">Incomplete</option>
            </select>
          </div>
        </div>
        <div className="col-1">
          <button
            className="btn btn-default"
            onClick={() => {
              setRegFilters({ search: "", waiver: "", status: "", paymentStatus: "" });
            }}
          >
            CLEAR
          </button>
        </div>
        <div className="col"></div>
        <div className="col-auto text-end">
          <div className="form-group">
            <label>Sort Order</label>
            <select
              className="form-control"
              name="SortOrder"
              value={sortOrder}
              onChange={(e) => {
                setSortOrder(e.target.value);
              }}
            >
              <option value=""></option>
              <option value="createdAt|desc">Created Last</option>
              <option value="firstName|asc">First Name A-Z</option>
              <option value="firstName|desc">First Name Z-A</option>
              <option value="lastName|asc">Last Name A-Z</option>
              <option value="lastName|desc">Last Name Z-A</option>
              <option value="email|asc">Email A-Z</option>
              <option value="email|desc">Email Z-A</option>
            </select>
          </div>
        </div>
        <div className="col-auto text-end">
          <div className="field-container">
            <div className="field-select"
              onClick={(e) => {
                setShowFieldList(!showFieldList);

              }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 7a3 3 0 113-3 3 3 0 01-3 3z"></path><path d="M18.84 13a5.81 5.81 0 00.08-1 6.19 6.19 0 00-.08-1.07l1.78-1.5a7.24 7.24 0 00-.53-1.36 9.09 9.09 0 00-.65-1.14 8.56 8.56 0 00-.93-1.14l-2.2.8a6.71 6.71 0 00-1.8-1l-.41-2.3A8.63 8.63 0 0012.66 3h-1.32a8.63 8.63 0 00-1.44.22l-.41 2.3a6.71 6.71 0 00-1.8 1l-2.2-.8a8.56 8.56 0 00-.93 1.14A9.09 9.09 0 003.91 8a7.24 7.24 0 00-.53 1.36l1.78 1.5A6.19 6.19 0 005.08 12a5.81 5.81 0 00.08 1l-1.79 1.5a8.62 8.62 0 00.52 1.37A9.09 9.09 0 004.54 17a11.18 11.18 0 00.92 1.14l2.19-.79a6.74 6.74 0 001.84 1.06l.41 2.3a7.81 7.81 0 001.45.22h1.3a7.81 7.81 0 001.45-.22l.41-2.3a6.74 6.74 0 001.84-1.06l2.19.79a11.18 11.18 0 00.92-1.14 9.09 9.09 0 00.65-1.14 8.62 8.62 0 00.52-1.37zm.36 2.45a6.64 6.64 0 01-.57 1c-.12.19-.26.36-.39.53l-1.55-.55-.53-.2-.44.36a5.9 5.9 0 01-1.57.91l-.53.2-.1.56-.29 1.63-.68.07L12 20h-.59c-.22 0-.43 0-.64-.06l-.29-1.63-.1-.56-.53-.2a5.9 5.9 0 01-1.57-.91l-.44-.36-.53.2-1.55.52-.39-.52a7.41 7.41 0 01-.58-1c-.09-.19-.18-.39-.26-.6L5.8 13.8l.44-.37-.09-.56a4.94 4.94 0 01-.07-.87 5.62 5.62 0 01.07-.92l.09-.55-.44-.37L4.54 9.1c.08-.2.17-.39.26-.59a9.31 9.31 0 01.58-1L5.79 7l1.56.57.52.19.44-.35a5.62 5.62 0 011.53-.88l.53-.2.1-.56.29-1.63c.22 0 .45-.06.68-.08L12 4h.59c.21 0 .43 0 .65.07l.29 1.63.1.56.53.2a5.62 5.62 0 011.53.88l.44.35.52-.19 1.56-.5c.14.17.28.35.4.53a8.84 8.84 0 01.59 1c.09.2.18.39.26.59l-1.26 1.04-.44.37.09.55a5.62 5.62 0 01.07.92 4.94 4.94 0 01-.07.87l-.09.56.44.37 1.27 1.06c-.08.21-.17.42-.27.62z"></path></svg></div>
            <div className={showFieldList ? "field-list field-list-show" : "field-list "}>
              {Object.entries(fieldList).map((key, i) => (
                // {key[0] ===}
                <div key={key[0]} className={availableFieldList.indexOf(key[0]) > -1 ? "form-group" : "d-none"}>
                  <label className="checkbox-container">
                    {key[0]}
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setFieldList({
                          ...fieldList,
                          [key[0]]: e.target.checked,
                        });
                        localStorage.setItem('fieldList', JSON.stringify({
                          ...fieldList,
                          [key[0]]: e.target.checked,
                        }));
                      }}
                      id={key[0] + "_cb"}
                      name={key[0] + "_cb"}
                      checked={fieldList[key[0]] || false}
                      value={fieldList[key[0]] || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="py-2"></div>
      <Fetching />
      <Message />

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              {createReminder &&
                <th className="d-table-cell">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        // alert(e.target.value);
                        if (!allSelected) {
                          setAllSelected(true);
                          let tmpList = [];
                          for (let i = 0; i < data?.filtered?.length; i++) {
                            tmpList.push(i);
                          }
                          setReminderEmailList(tmpList);
                          var ele = document.getElementsByName('reminderCheck');
                          for (var i = 0; i < ele.length; i++) {
                            if (ele[i].type == 'checkbox')
                              ele[i].checked = true;
                          }

                        } else {
                          setAllSelected(false);
                          setReminderEmailList([]);
                          var ele = document.getElementsByName('reminderCheck');
                          for (var i = 0; i < ele.length; i++) {
                            if (ele[i].type == 'checkbox')
                              ele[i].checked = false;
                          }
                        }
                      }}
                      id="reminderCheckAll"
                      name="reminderCheckAll"
                      checked={allSelected}
                    />
                    <span className="checkmark"></span>
                  </label>
                </th>
              }
              {fieldList['company'] &&
                <th className="d-none d-md-table-cell">Company</th>
              }
              {fieldList['title'] &&
                <th className="d-none d-md-table-cell">Title</th>
              }
              {fieldList['firstName'] &&
                <th className="d-table-cell">First Name</th>
              }
              {fieldList['lastName'] &&
                <th className="d-table-cell">Last Name</th>
              }
              {fieldList['email'] &&
                <th className="d-table-cell">Email</th>
              }
              {fieldList['phone'] &&
                <th className="d-none d-md-table-cell">Phone</th>
              }
              {fieldList['address1'] &&
                <th className="d-none d-md-table-cell">Address 1</th>
              }
              {fieldList['address2'] &&
                <th className="d-none d-md-table-cell">Address 2</th>
              }
              {fieldList['city'] &&
                <th className="d-none d-md-table-cell">City</th>
              }
              {fieldList['state'] &&
                <th className="d-none d-md-table-cell">State</th>
              }
              {fieldList['postal'] &&
                <th className="d-none d-md-table-cell">Postal</th>
              }
              {fieldList['createdDate'] &&
                <th className="d-none d-md-table-cell">Created</th>
              }
              {fieldList['updatedDate'] &&
                <th className="d-none d-md-table-cell">Updated</th>
              }
              {fieldList['status'] &&
                <th className="d-none d-md-table-cell">Status</th>
              }
              {commItem && fieldList['paymentStatus'] ?
                <th className="d-none d-md-table-cell">Payment Status</th>
                : <></>}
              {eventData?.allowGuests && fieldList['guest'] &&
                <th className="d-none d-md-table-cell">Guest</th>
              }
              {eventData?.allowWaivers && fieldList['waiver'] &&
                <th className="d-none d-md-table-cell">Waiver</th>
              }

              <th className="d-none d-md-table-cell">Check-In</th>
              {fieldList['dealer'] &&
                <th className="d-none d-md-table-cell">Dealer</th>
              }
              {fieldList['experience'] &&
                <th className="d-none d-md-table-cell">Experience</th>
              }
              {fieldList['modelInterest'] &&
                <th className="d-none d-md-table-cell">Model Interest</th>
              }
              {fieldList['purchaseTimeFrame'] &&
                <th className="d-none d-md-table-cell">Purchase Timeframe</th>
              }
              {eventData?.additionalFields?.map((f, i) => (<>
                <th key={i} className={fieldList[f.fieldName] ? "d-none d-md-table-cell" : "d-none"}>{f.fieldLabel}</th>
                {f.fieldType === "CommerceItem" &&
                  <th key={100 + i} className={fieldList[f.fieldName + "Cost"] ? "d-none d-md-table-cell" : "d-none"}>{f.fieldLabel} Cost</th>

                }
              </>
              ))}
              {commItem && eventData?.paymentTax && fieldList['subCost'] ?
                <th className="d-none d-md-table-cell">Subtotal</th>
                : <></>}
              {commItem && eventData?.paymentTax && fieldList['taxCost'] ?
                <th className="d-none d-md-table-cell">Tax</th>
                : <></>}
              {commItem && fieldList['totalCost'] ?
                <th className="d-none d-md-table-cell">Total</th>
                : <></>}
              {commItem && fieldList['adyenRef'] ?
                <th className="d-none d-md-table-cell">Adyen Ref</th>
                : <></>}
              {commItem && fieldList['adyenLink'] ?
                <th className="d-none d-md-table-cell">Adyen Link</th>
                : <></>}
            </tr>
          </thead>
          <tbody>
            {data?.filtered?.map((r, i) => (
              <tr
                key={i}
                className={alertRules(r) + "my-2 pointer"}

              >
                {createReminder &&
                  <td className="d-table-cell">                         <label className="checkbox-container">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        let tmpList = reminderEmailList;
                        // console.log(reminderEmailList.indexOf(i) > 0 ? "selected" : "not selected");

                        if (reminderEmailList.indexOf(i) < 0) {

                          tmpList.push(i);

                        } else {
                          setAllSelected(false);
                          tmpList.splice(tmpList.indexOf(i), 1);
                        }
                        setReminderEmailList(tmpList);
                        // console.log(tmpList);
                      }}
                      id={"reminderCheck" + i}
                      name="reminderCheck"
                    // checked={reminderEmailList.indexOf(i) >= 0}
                    />
                    <span className="checkmark"></span>
                  </label>
                  </td>
                }
                {fieldList['company'] &&

                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.company}
                  </td>
                }
                {fieldList['title'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.title}
                  </td>
                }
                {fieldList['firstName'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-table-cell"
                  >
                    {r.firstName}
                  </td>
                }
                {fieldList['lastName'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-table-cell"
                  >
                    {r.lastName}
                  </td>
                }
                {fieldList['email'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-table-cell"
                  >
                    {r.email}
                  </td>
                }
                {fieldList['phone'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.phone}
                  </td>
                }
                {fieldList['address1'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.address1}
                  </td>
                }
                {fieldList['address2'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.address2}
                  </td>
                }
                {fieldList['city'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.city}
                  </td>
                }
                {fieldList['state'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.state}
                  </td>
                }
                {fieldList['postal'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.postal}
                  </td>
                }
                {fieldList['createdDate'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.createdDate}
                  </td>
                }
                {fieldList['updatedDate'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.updatedDate}
                  </td>
                }
                {fieldList['status'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.status ? r.status : "XXX"}
                  </td>
                }
                {commItem && fieldList['paymentStatus'] ?
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"

                  >
                    {r.adyenPaymentStatus}
                  </td>
                  : <></>}
                {r.guestId && eventData?.allowGuests && fieldList['guest'] && (
                  <td
                    className="d-none d-md-table-cell"
                    onClick={(e) => {

                      e.preventDefault();
                      e.stopPropagation();
                      // console.log(r.guestId);
                      history("/manage/registration/" + r.guestId);
                    }}
                  >
                    View Guest
                  </td>
                )}
                {r.parentId && eventData?.allowGuests && fieldList['guest'] && (
                  <td
                    className="d-none d-md-table-cell"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // console.log(r.parentId);
                      history("/manage/registration/" + r.parentId);
                    }}
                  >
                    View Primary
                  </td>
                )}
                {!r.parentId && !r.guestId && eventData?.allowGuests && fieldList['guest'] && (
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                  >
                    --
                  </td>
                )}
                {eventData?.allowWaivers && fieldList['waiver'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.hasWaiver
                      ? "Complete"
                      : eventData.allowWaivers
                        ? "Incomplete"
                        : "N/A"}
                  </td>
                }
                <td className="d-none d-md-table-cell">
                  {r.status == "Registered" &&
                    (r.hasWaiver || !eventData.allowWaivers) && (
                      <i
                        className="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          let tmpRegs = data.registrations;
                          tmpRegs[r.idx].status = "Checked-In";
                          db.collection("registrations")
                            .doc(tmpRegs[r.idx].id)
                            .set(tmpRegs[r.idx]);
                          setData({
                            ...data,
                            registrations: tmpRegs,
                            filter: true,
                          });
                        }}
                      >
                        <CheckIcon />
                      </i>
                    )}
                </td>
                {fieldList['dealer'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.dealer}
                  </td>
                }
                {fieldList['experience'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.experience}
                  </td>
                }
                {fieldList['modelInterest'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.modelInterest}
                  </td>
                }
                {fieldList['purchaseTimeFrame'] &&
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"
                  >
                    {r.purchaseTimeFrame}
                  </td>
                }
                {eventData?.additionalFields?.map((f, i) => (

                  <>


                    <td key={i}
                      onClick={() => {
                        history("/manage/registration/" + r.id);
                      }}
                      className={fieldList[f.fieldName] ? "d-none d-md-table-cell" : "d-none"}
                    >

                      {f.fieldType === "Checkbox" ? r[f.fieldName] ? "Yes" : "No" : r[f.fieldName]}

                    </td>

                    {f.fieldType === "CommerceItem" &&
                      <td key={i + 100}
                        onClick={() => {
                          history("/manage/registration/" + r.id);
                        }}
                        className={fieldList[f.fieldName + "Cost"] ? "d-none d-md-table-cell" : "d-none"}
                      >
                        {r[f.fieldName + "Cost"]}
                      </td>
                    }
                  </>

                ))}
                {commItem && eventData?.paymentTax && fieldList['subCost'] ?
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"

                  >
                    {r.subCost}
                  </td>
                  : <></>}
                {commItem && eventData?.paymentTax && fieldList['taxCost'] ?
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"

                  >
                    {r.taxCost}
                  </td>
                  : <></>}
                {commItem && fieldList['totalCost'] ?
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"

                  >
                    {r.totalCost}
                  </td>
                  : <></>}
                {commItem && fieldList['adyenRef'] ?
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"

                  >
                    {r.adyenRef}
                  </td>
                  : <></>}
                {commItem && fieldList['adyenLink'] ?
                  <td
                    onClick={() => {
                      history("/manage/registration/" + r.id);
                    }}
                    className="d-none d-md-table-cell"

                  >
                    {r.adyenLink}
                  </td>
                  : <></>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(user.role === 1 || user.role === 2) && (
        <div>
          <div className="py-5"></div>
          <div className="row">
            <div className="col">
              {eventData.allowWaivers && (
                <button
                  className="btn btn-link"
                  onClick={() =>
                    window.confirm(
                      "Are you sure you want to download all waivers, this may take a few minutes?"
                    )
                      ? downloadAllWaivers()
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <DownloadIcon />
                  </i>{" "}
                  All Waivers
                </button>
              )}
            </div>
            <div className="col">
              <div>
                <button
                  className={
                    allSubmissions.length > 0 ? "d-none" : "btn btn-link"
                  }
                  onClick={() =>
                    window.confirm(
                      "Are you sure you want to download all data, this may take a few minutes?"
                    )
                      ? downloadSubmissions()
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <DownloadIcon />
                  </i>{" "}
                  All Data
                </button>
                <div className={allSubmissions.length > 0 ? "" : "d-none"}>
                  <CsvDownloadButton target="_blank" delimiter="," data={allSubmissions} className="btn btn-link" />
                </div>
              </div>
            </div>
            {user.role === 1 &&
              <div className="col text-end">
                <button
                  className="btn btn-link"
                  onClick={() =>
                    window.confirm("Are you sure to delete ALL registrations?")
                      ? deleteAllRegistrations()
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <DeleteIcon />
                  </i>{" "}
                  All Registrations
                </button>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};
export default Registrations;
