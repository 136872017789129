import React, {  useEffect, useContext,useReducer } from "react";
import { Navigate} from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon, userRoles } from "../../../Constants";
import { useNavigate } from "react-router-dom";

const Invite = () => {
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });
  const [data, setData] = useReducer(reducer, {groups:[],groupList:[]});
  let history = useNavigate();



  useEffect(() => {
    let groups = [];
    db.collection("groups")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          groups.push(doc.data());
        });
        setData({
          groupList:groups
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, []);

  if (!user.isAuthenticated) {
    return <Navigate to="/manage/sign-in" />;
  }

  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }


  const handleSubmit = () => {
    if(data.email && data.role) {
      var getUser = firebase.functions().httpsCallable("app/getUserByEmail");
      getUser(data.email).then(function (result) {
        if(result.data.uid) {
          setData({message: 'A user with this email address already exists.'});
        } else {

          var createUser = firebase.functions().httpsCallable("app/createUser");
          createUser({  email:data.email, claims: {role:+data.role,groups:data.groups} })
            .then(function (result) {
              // var auth = admin.auth();
              var emailAddress = data.email;
              db.collection("invites").doc().set({
                email: data.email,
                role: data.role,
                groups: data.groups
            })
            .then(function() {
              var sendInvite = firebase.functions().httpsCallable("app/sendInvite");
              sendInvite(data.email).then(function (result) {
                history('/manage/users');
              
              });            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
            

            })
            .catch(function (error) {
              console.log("Error updating claims:", error);
            });
        }
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    
  } else {
    if(!data.email) {
      setData({message: "Email is required."});
    } else if(!data.role) {
      setData({message: "Role is required."});
    }
    
  }
  };
  const handleChange = (e) => {
    setData({
      ...setData, [e.target.name]: e.target.value
    });
  
  };

  const handleGroupsChange = (e) => {
    let groupArray = data.groups
      ? data.groups
      : [];
    console.log(groupArray);
    if (e.target.checked && !groupArray.includes(e.target.value)) {
      console.log('here');
      groupArray.push(e.target.value);
    }
    if (!e.target.checked && groupArray.includes(e.target.value)) {
      console.log('there');
      groupArray.splice(groupArray.indexOf(e.target.value), 1);
    }
    setData({
      ...setData, [e.target.name]: groupArray
    });
  };

  return (
    <div className="container">
      
      <div>
      <h1>Invite User</h1>
        <div className="py-3"></div>

        <div className="row">
          <div className="col-12 col-md">
            <div className="form-group">
              <label className="form-label" htmlFor="email">Email</label>
              <input
                type="email" required
                id="email"
                name="email"
                value={data.email || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
           
          </div>
          <div className="col-12 col-md">
            <div className="form-group">
              <label className="form-label" htmlFor="role">
                Role
              </label>
              <select
                className="form-control"
                id="role"
                name="role"
                onChange={handleChange}
                value={
                  data.role
                    ? data.role
                    : ""
                }
              >
                <option value=""></option>
                {userRoles.map((item, index) => (
                  <option key={index} value={index + 1}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <label className="form-label">
              Groups
            </label>
            {data.groupList.map((g, i) => (
              <div key={i} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={
                    data.groups?.indexOf(g.id) >= 0
                      ? "checked"
                      : ""
                  }
                  value={g.id}
                  name="groups"
                  id={"group-" + i}
                  onChange={handleGroupsChange}
                />
                <label className="form-check-label" htmlFor={"group-" + i}>
                  {g.title} ({g.description})
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-default"
            >
              Cancel
            </button>
          </div>
          <div className="col text-end">
            
          </div>
        </div>
        <div className="py-3"></div>

        <Message />
      </div>
    </div>
  );
};

export default Invite;
