import React, { useState, useEffect, useContext, useReducer } from "react";
import { Navigate } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon } from "../../../Constants";
import "../../../assets/css/groups.css";

const DealerLocator = () => {
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });

  const [data, setData] = useReducer(reducer, { items: [],
    isFetching: false,
    message: null, });

  const [inputs, setInputs] = useState({hideClearButton: false,postal:null,updatedOn:null, completed:0,status:null,results:[]});
  
  
  useEffect(() => {

    db.collection('locator').doc('import_status').get().then((d) => { 
      let r = d.data();
      setInputs({...inputs, completed: r.writeTotal, updatedOn: r.updatedOn, status:r.status});
      console.log(d.data()); });
  }, []);
  if (!user.isAuthenticated) {
    return <Navigate to="/manage/sign-in" />;
  }
  
  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }


  const handleChange = (e) => {
    console.log('here');
    setInputs({
      ...inputs, postal: e.target.value });    
  };

  const handleSubmit = () => {
    let r = [];
    setInputs({...inputs,results:[]});
    db.collection("locator")
      .where("postal", "==", inputs.postal)
      .orderBy("dealerDistance")
      .limit(5)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          db.collection("dealers").where("code","==",doc.data().code).limit(1).get().then((qs) =>{
            if(qs.docs.length > 0)
             { 
               console.log(qs.docs[0].data());
               if(qs.docs[0]) {
                
                r.push(qs.docs[0].data());
                console.log(r);
              setInputs({...inputs,results: r});
               console.log(r);
               }
            }
          })
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
  

  const updateLocator = (path) => {
    try {
      var updateLocator = firebase.functions().httpsCallable('app/updateLocator');
      updateLocator()
        .then(function(result) {
            console.log('Locator success: ' + JSON.stringify(result));
        })
        .catch(function(err) {
            console.log('Locator failed, see console,');
            console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
 
  }
  return (
    <div className="container">
        <h1>Dealer Locator</h1>
        <div className="py-2"></div>
        <div className={inputs.showClearButton ? "d-none":""}>
            <button
              className="btn btn-theme"
              onClick={() =>
                window.confirm("Are you sure you want to delete all dealer locator data and refresh the data set?  (this will take several minutes)")
                  ? updateLocator()
                  : console.log("Action cancelled by user.")
              }
            >
              Refresh Locator Data
            </button>
            </div>

            <div>

            <div>Completed: {inputs.completed}</div>
            <div>Status: {inputs.status}</div>
            <div>Updated On: {inputs.updatedOn?.toString()}</div>
      </div>
      <div className="py-3"></div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="postal">
                Postal Code
              </label>
              <input
                type="text"
                id="postal"
                name="postal"
                value={inputs?.postal || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Search
            </button>
          </div>
      
          <div className="col text-end">
                
          </div>
        </div>
        <div className="py-3"></div>
        {inputs.results.map((dealer, i) => (
                <div
                  key={i}
                  className=""
                >
                  {dealer.code} - {dealer.name} - {dealer.state}
                              </div>
              ))}
        <Message />
      </div>
  );
};


export default DealerLocator;
