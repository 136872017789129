import React, { useState, Fragment, useEffect,useContext,Suspense } from "react";
import { Route, Routes, Link,BrowserRouter } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import Help from "./Help";
import Permissions from "./Permissions";
import Events from "./Events/Events";
import Event from "./Events/Event";
import EventName from "./Events/EventName";
// import packageJson from '../../../package.json';
import Registration from "./Events/Registration";
import Registrations from "./Events/Registrations";
import Reports from "./Events/Reports";
import Users from "./Configuration/Users";
import Settings from "./Configuration/Settings";
import GlobalReports from "./Configuration/GlobalReports";
import Dealers from "./Configuration/Dealers";
import DealerLocator from "./Configuration/DealerLocator";
import Models from "./Configuration/Models";
import Groups from "./Configuration/Groups";
import Oops from "../main/Oops";
import Invite from "./Configuration/Invite";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import Profile from "./Profile";
import {
  UserIcon,
  GearIcon,
  ArrowDoubleRightIcon,
  ArrowDoubleDownIcon,
  LoginIcon,
  MenuIcon,
  UserGroupIcon,
  QuestionIcon,
  CarIcon,
  KeyIcon,
  GlobeIcon,
  StackIcon,
  FlagIcon,
  ChartIcon,
  MapIcon,
  DeployTarget
} from "../../Constants";
import { EventDataContext,EventDataProvider } from "./EventDataContext"

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import PasswordForgetPage from './components/ForgotPassword';
// import AccountPage from './components/Account';
// import ManagePage from './components/Manage';
import "../../assets/css/dashboard.css";

const DashboardLayout = (props) => {
  const { user } = useContext(firebaseAuth);
  const [showTitles, setShowTitles] = useState("sidebar-titles-hide");
  const [showSidebar, setShowSidebar] = useState("sidebar-hide");
  const [sidebarOpen, setSidebarOpen] = useState(false);


  // const caching= ()=> {
  //   console.log(packageJson.version);
  //   console.log('version');
  // let version = localStorage.getItem('version');
  //     if(version!=packageJson.version)
  //     {
  //         if('caches' in window){
  //          caches.keys().then((names) => {
  //         // Delete all the cache files
  //         names.forEach(name => {
  //             caches.delete(name);
  //         })
  //     });
  
  //     // Makes sure the page reloads. Changes are only visible after you refresh.
  //     window.location.reload(true);
  // }
  
  //       localStorage.clear();
  //       localStorage.setItem('version',packageJson.version);
  //     }
  // };

  useEffect(() => {
  document.getElementById('loading-container').classList.add('hide-loading-container');
  // caching();
  
  }, []);
  function toggleSidebar() {
    if (showSidebar === "sidebar-hide") {
      setShowSidebar("");
      setShowTitles("sidebar-titles-show");
    } else {
      setShowSidebar("sidebar-hide");
      setShowTitles("sidebar-titles-hide");
    }
  }

  function toggleSidebarTitles() {
    if (showTitles === "sidebar-titles-show") {
      setShowTitles("sidebar-titles-hide");
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
      setShowTitles("sidebar-titles-show");
    }
  }
  function AuthenticatedSidebarLinks(props) {
    return (
      <Fragment>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link" to="/manage">
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/manage/event">
              <i className="md">
                <FlagIcon />
              </i>
              <span className={showTitles + " px-2"}>Event</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/manage/registrations">
              <i className="md">
                <StackIcon />
              </i>
              <span className={showTitles + " px-2"}>Registrations</span>
            </Link>
          </li>

        </ul>
        <h4
          className={
            user?.role === 1 ? showTitles +
            " d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted":"d-none"
          }
        >
          <span className={showTitles + " px-2"}>Configuration</span>
        </h4>
        <ul className="nav flex-column mb-2">
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/settings">
              {" "}
              <i className="md">
                <GearIcon />
              </i>
              <span className={showTitles + " px-2"}>Settings</span>
            </Link>
          </li>
          {/* <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/global-reports">
              {" "}
              <i className="md">
                <GlobeIcon />
              </i>
              <span className={showTitles + " px-2"}>Summary Reports</span>
            </Link>
          </li> */}
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/dealers">
              {" "}
              <i className="md">
                <KeyIcon />
              </i>
              <span className={showTitles + " px-2"}>Dealers</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/dealer-locator">
              {" "}
              <i className="md">
                <MapIcon />
              </i>
              <span className={showTitles + " px-2"}>Dealer Locator</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/models">
              {" "}
              <i className="md">
                <CarIcon />
              </i>
              <span className={showTitles + " px-2"}>Models</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/groups">
              {" "}
              <i className="md">
                <UserGroupIcon />
              </i>
              <span className={showTitles + " px-2"}>Groups</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/users">
              {" "}
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Users</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" to="/manage/help">
              {" "}
              <i className="md">
                <QuestionIcon />
              </i>
              <span className={showTitles + " px-2"}>Help</span>
            </Link>
          </li> */}
        </ul>
      </Fragment>
    );
  }
  function NonAuthenticatedSidebarLinks(props) {
    return (
      <Fragment>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link" to="/manage/sign-in">
              <i className="md">
                <LoginIcon />
              </i>
              <span className={showTitles + " px-2"}>Login</span>
            </Link>
          </li>
        </ul>
      </Fragment>
    );
  }

  function ShowSidebarLinks(props) {
    if (user.isAuthenticated) {
      return <AuthenticatedSidebarLinks />;
    }
    return <NonAuthenticatedSidebarLinks />;
  }

  function AuthenticatedLinks(props) {
    return (
      <Fragment>
        <li className="nav-item">
          <Link className="nav-link" to="/manage/profile">
            <i className="md light">
              <UserIcon />
            </i>
          </Link>
        </li>
      </Fragment>
    );
  }
  function NonAuthenticatedLinks(props) {
    return (
      <li className="nav-item text-nowrap">
        <Link className="nav-link" to="/manage/sign-in">
          Login
        </Link>
      </li>
    );
  }

  function ShowLinks(props) {
    if (user.isAuthenticated) {
      return <AuthenticatedLinks />;
    }
    return <NonAuthenticatedLinks />;
  }
  // const eventData = {name:'test'};
  
  return (
    <EventDataProvider>

    <div id="dashboard-layout" >
      <nav
        id="nav"
        className="navbar px-3 navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow"
      >
        <div className="d-md-none col-auto px-2" onClick={toggleSidebar}>
          <i className="sm light">
            <MenuIcon />
          </i>
        </div>
        <div className="col-auto mr-0">
        <Link className="navbar-brand nav-link" to="/manage">
          pcna<span>events</span> <span className="fs-xxs">v{process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "0000000"}{DeployTarget === "staging" ? " (STAGING)" :""}</span>
        </Link>
        </div>
        <div className="col navbar-brand"><EventName/></div>
        <ul className="navbar-nav px-3">
          <ShowLinks />
        </ul>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <nav
            className={"col-auto px-0 sidebar " + showSidebar}
            id="app-drawer"
          >
            <div
              className="d-xl-none d-md-block d-none pl-md-3 px-2 mt-2"
              onClick={toggleSidebarTitles}
            >
              <i className={sidebarOpen ? "d-none md" : "md"}>
                <ArrowDoubleRightIcon />
              </i>
              <i className={sidebarOpen ? "md" : "d-none md"}>
                <ArrowDoubleDownIcon />
              </i>
              <span className={showTitles + " px-2"}></span>
            </div>

            <div className="sidebar-sticky">
              <ShowSidebarLinks />
            </div>
          </nav>
          <main id="main" role="main" className="col ml-sm-auto pe-md-4">
          <Suspense fallback={<div id="loading-container">
            <img
              src="/assets/images/loading.gif"  width="100"
              alt="Porsche Cars North America"
            />
          </div>}>
          <DndProvider backend={HTML5Backend}>
          {/* <BrowserRouter> */}

            <Routes>
            <Route path="/help" element={<Help/> } />
            <Route path="/permissions" element={<Permissions/> } />

              <Route path="/profile" element={<Profile/> } />
              <Route exact path="/sign-in" element={<SignIn/> } />
              <Route path="/reset-password" element={<ResetPassword/> } />
              <Route path="/forgot-password" element={<ForgotPassword/> } />
              <Route path="/users" element={<Users/> } />
              <Route path="/invite" element={<Invite/> } />
              <Route path="/groups" element={<Groups/> } />
              <Route path="/settings" element={<Settings/> } />
              <Route exact path="/oops" element={<Oops/> } />
              <Route path="/dealers" element={<Dealers/> } />
              <Route path="/dealer-locator" element={<DealerLocator/> } />
              <Route path="/models" element={<Models/> } />
              <Route path="/global-reports" element={<GlobalReports/> } />
              <Route path="/event" element={<Event/> } />
              <Route path="/registrations" element={<Registrations/> } />
              <Route path="/registration/:id" element={<Registration/> } />
              <Route path="/registration" element={<Registration/> } />
              <Route path="/reports" element={<Reports/> } />
              
              <Route exact sensitive={false} path="/" element={<Events/> }></Route>
              {/*<Route path='/forgot-password' element={<ForgotPassword/> } />
                                                        <Route path='/account' element={<Account/> } />
                                                        <Route path='/manage' element={<Manage/> } /> */}
            </Routes>
            {/* </BrowserRouter> */}

            </DndProvider>
            </Suspense>
          </main>
        </div>
      </div>
    </div>
    </EventDataProvider>
  );
};

export default DashboardLayout;
